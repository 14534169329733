function hashCode(s) {
  if(s === null){
    return 0
  }
  let h
  for(let i = 0; i < s.length; i++) {
    h = Math.imul(31, h) + s.charCodeAt(i) | 0
  }
  return h
}

function _b64ToUtf8(str) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

function _isEmpty(str) {
  return str === void 0 || str === null || str === ""
}

fetch('./config/config.json', { cache: 'reload' })
    .then(res => res.json())
    .then(config => {
      import(/* @vite-ignore */ config.ssoKeycloakAdapter)
        .then(() => {
          // executed after the keycloack adapter script is loaded
          window.keycloak = new Keycloak({
            url: config.ssoUrl,
            realm: config.ssoRealm,
            clientId: config.ssoClient
          });
          window.keycloak.init({
            onLoad: 'login-required',
            promiseType: 'native',
            checkLoginIframe: false,
            pkceMethod: 'S256'
          }).then(() => {

            Promise.all([
              import('./app.js'),
              import('./services/OrganisationService.js'),
              import('./views/Verwaltung/OrgElemente/OrgElementeService.js')
            ]).then(([_, OrganisationService, OrgElementeService]) => {
                const params = new URLSearchParams(location.search)
                // get easyGov token from url (if present)
                let token = params.get('processtoken')
                let isEasyGov = true
                // get code token from url (if present)
                if (token == null) {
                  token = params.get('codetoken')
                  isEasyGov = false
                }
                // clear session storage, we have an initial load
                const newTokenHash = hashCode(token)
                const oldTokenHash = sessionStorage.getItem('hash')

                if (newTokenHash.toString() !== oldTokenHash) {
                  sessionStorage.clear()
                  sessionStorage.setItem('hash', newTokenHash)
                }
                // set easygov or code token if present
                window.xheaders = {
                  authorization: token !== null ? token : '',
                  iseasygov: isEasyGov === true,
                  supportorganisation: {
                    id: '',
                    name: ''
                  }
                }

                // check if a new organisation will be created and send event to matomo
                new OrganisationService.default().exist()
                  .then(orgServiceResponse => {
                    // check if user is authorized
                    if (window.xheaders.authorization === '' &&
                      window.keycloak.tokenParsed.sichemOrgID === undefined) {
                      // load notAuthorized page
                      document.querySelector('body').innerHTML = `
                          <main>
                               <sichem-header></sichem-header>
                               <sichem-not-authorized title="auth.error" msg="auth.error.msg"></sichem-not-authorized>
                          </main>`
                    } else {
                      // load page
                      const orgElementKey = sessionStorage.getItem('orgElementKey')
                      if (window.xheaders.authorization !== '' && orgElementKey === null) {
                        new OrgElementeService.default().getStartingOrgElement()
                          .then(orgElementServiceResponse => {
                            if (orgElementServiceResponse !== undefined && orgElementServiceResponse['id']) {
                              sessionStorage.setItem('orgElementKey', orgElementServiceResponse.id)
                              sessionStorage.setItem('orgElementName', orgElementServiceResponse.name)
                              sessionStorage.setItem('orgElementGrundliste', orgElementServiceResponse.grundliste)
                            }
                            document.querySelector('body').innerHTML = `
                          <main>
                                <sichem-header></sichem-header>
                                <sichem-horizontal-navigation></sichem-horizontal-navigation>
                                <isceco-message id="messagecontent" lifetime="5"></isceco-message>
                                <sichem-main-view id="mainview"></sichem-main-view>
                                <sichem-footer></sichem-footer>
                          </main>`
                          })
                      } else {
                        document.querySelector('body').innerHTML = `
                          <main>
                                <sichem-header></sichem-header>
                                <sichem-horizontal-navigation></sichem-horizontal-navigation>
                                <isceco-message id="messagecontent" lifetime="5"></isceco-message>
                                <sichem-main-view id="mainview"></sichem-main-view>
                                <sichem-footer></sichem-footer>
                          </main>`
                      }
                    }

                    if (_paq !== null && typeof (_paq) !== 'undefined') {
                      // send matomo information about the user mail and extid
                      _paq.push(['setUserId', `${window.keycloak.tokenParsed.email}/${window.keycloak.tokenParsed.userExtId}`])
                      if (!orgServiceResponse.exist) {
                        const dateTime = formatDateTime(new Date())
                        if (isEmpty(window.xheaders.authorization)) {
                          const sichemOrgName = _isEmpty(window.keycloak.tokenParsed.sichemOrgName) ?
                            '' : window.keycloak.tokenParsed.sichemOrgName.split('\\')[1]
                          _paq.push(['trackEvent', 'SICHEM', 'CREATE_ORG_SICHEM', `${sichemOrgName} - ${dateTime}`])
                        } else {
                          const tokenOrgName = JSON.parse(_b64ToUtf8(window.xheaders.authorization.split('.')[1])).organisationName
                          if (window.xheaders.iseasygov) {
                            _paq.push(['trackEvent', 'EASYGOV', 'CREATE_ORG_EASYGOV', `${tokenOrgName} - ${dateTime}`])
                          } else {
                            _paq.push(['trackEvent', 'CODE', 'CREATE_ORG_CODE', `${tokenOrgName} - ${dateTime}`])
                          }
                        }
                      }
                    }
                  })
              })

              // used in Global Logout context with EasyGov
              window.keycloak.onTokenExpired = () => {
                keycloak.updateToken(-1)
                  .catch(function() {
                    console.error('Failed to refresh the token, or the session has expired') // NOSONAR
                  })
              }
          })
        })
    })
